module.exports = [{
      plugin: require('/builds/louy/my-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Louayt Alakkad","short_name":"LA","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/louay.jpg"},
    },{
      plugin: require('/builds/louy/my-website/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/louy/my-website/src/components/Layout.js"},
    },{
      plugin: require('/builds/louy/my-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/louy/my-website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":560,"linkImagesToOriginal":false,"withWebp":true},
    }]
